import React from 'react';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const DownloadProducts = ({elements}) => {
  const fileHeaders = [
    'Name',
    'Recipient type',
    'Account number',
    'Sort code',
    'Recipient bank country',
    'Currency',
    'Amount',
    'Payment reference'
  ];

  function convertJSONToCSV(jsonData, columnHeaders) {
    if (jsonData?.length === 0) {
      return '';
    }
    const headers = columnHeaders.join(',') + '\n';

    const rows = jsonData?.map((row) => {
        return columnHeaders.map((field) => row[field] || '').join(',');
      }).join('\n');

    return headers + rows;
  }

  function downloadCSV(jsonData, headers) {
    const csvData = convertJSONToCSV(jsonData, headers);

    if (csvData === '') {
      alert('No data to export');
    } else {
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `revolut_export.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  return (
    <Button className="incGridButton" onClick={() => {
      downloadCSV(elements, fileHeaders);
    }}>
      <FileDownloadOutlinedIcon fontSize="small" />EXPORT REVOLUT
    </Button>
  );
};
export default DownloadProducts;