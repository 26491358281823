import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://node.skilledsolutions.co.uk/api/';
//updatePDetails
class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
  sendForgotEmail(data) {
    return axios.post(API_URL + 'auth/forgotEmail', data , { headers: authHeader() });
  }
  getUsers() {
    return axios.get(API_URL + 'sm/users', { headers: authHeader() });
  }
  updateUsers(data) {
    return axios.post(API_URL + 'sm/updateUser', data, { headers: authHeader() });
  }
  deleteUser(data) {
    return axios.post(API_URL + 'sm/deleteUser', data, { headers: authHeader() });
  }
  updateRole(data) {
    return axios.post(API_URL + 'sm/updateRole', data, { headers: authHeader() });
  }
  getUserDetails() {
    return axios.get(API_URL + 'associates/userDetails', { headers: authHeader() });
  }
  getArchiveX() {
    return axios.get(API_URL + 'archive/archiveX', { headers: authHeader() });
  }
  postTicket(data) {
    return axios.post(API_URL + 'ticket/postTicket', data , { headers: authHeader() });
  }
  getUserTickets(data) {
    return axios.post(API_URL + 'ticket/getUserTickets', data , { headers: authHeader() });
  }
  getDepartmentTickets(data) {
    return axios.post(API_URL + 'ticket/getDepartmnetTickets', data , { headers: authHeader() });
  }
  sendMessage(data) {
    return axios.post(API_URL + 'ticket/postMessage', data , { headers: authHeader() });
  }
  getMessages(data) {
    return axios.post(API_URL + 'ticket/getMessages', data , { headers: authHeader() });
  }
  countUnseen(data) {
    return axios.post(API_URL + 'ticket/unseen', data , { headers: authHeader() });
  }
  countUnseenDep(data) {
    return axios.post(API_URL + 'ticket/unseenDep', data , { headers: authHeader() });
  }
  markMessages(data) {
    return axios.post(API_URL + 'ticket/markSeen', data , { headers: authHeader() });
  }
  closeTicket(data) {
    return axios.post(API_URL + 'ticket/closeTicket', data , { headers: authHeader() });
  }
  getProfilesData() {
    return axios.get(API_URL + 'associates/profilesData', { headers: authHeader() });
  }
  getProfileData(data) {
    return axios.post(API_URL + 'associates/profileData',data, { headers: authHeader() });
  }
  getContactNames() {
    return axios.get(API_URL + 'associates/contactNames', { headers: authHeader() });
  }
  updateBasicInfo(data) {
    return axios.post(API_URL + 'associates/updateBasicInfo',data, { headers: authHeader() });
  }
  getAuditData() {
    return axios.get(API_URL + 'compliance/auditData', { headers: authHeader() });
  }
  updateDl(data) {
    return axios.post(API_URL + 'associates/updateDlData',data, { headers: authHeader() });
  }
  updateRtw(data) {
    return axios.post(API_URL + 'associates/updateRtwData',data, { headers: authHeader() });
  }
  getOnboardedNames() {
    return axios.get(API_URL + 'compliance/onboardedNames', { headers: authHeader() });
  }
  getAddress(data) {
    return axios.post(API_URL + 'compliance/address',data, { headers: authHeader() });
  }
  postIncidentForm(data) {
    return axios.post(API_URL + 'compliance/incidentForm',data, { headers: authHeader() });
  }
  getDepotList() {
    return axios.get(API_URL + 'kpi/depotList', { headers: authHeader() });
  }
  insertDepot(data) {
    return axios.post(API_URL + 'kpi/insertDepot',data, { headers: authHeader() });
  }
  deleteDepot(data) {
    return axios.post(API_URL + 'kpi/deleteDepot',data, { headers: authHeader() });
  }
  depotScorecards(data) {
    return axios.post(API_URL + 'kpi/depotScorecards',data, { headers: authHeader() });
  }
  pmUpdate(data) {
    return axios.post(API_URL + 'osmTools/pmUpdate',data, { headers: authHeader() });
  }
  sdUpdate(data) {
    return axios.post(API_URL + 'osmTools/sdUpdate',data, { headers: authHeader() });
  }
  getPmsdValidation() {
    return axios.get(API_URL + 'osmTools/pmsdValidation', { headers: authHeader() });
  }
  getStatisticData(data) {
    return axios.post(API_URL + 'osmTools/statisticsData',data, { headers: authHeader() });
  }
  getChannels() {
    return axios.get(API_URL + 'osmTools/channels', { headers: authHeader() });
  }
  insertChannel(data) {
    return axios.post(API_URL + 'osmTools/insertChannel',data, { headers: authHeader() });
  }
  deleteChannel(data) {
    return axios.post(API_URL + 'osmTools/deleteChannel',data, { headers: authHeader() });
  }
  sendMessageChannels(data) {
    return axios.post(API_URL + 'osmTools/sendMessages',data, { headers: authHeader() });
  }
  getIncidents() {
    return axios.get(API_URL + 'compliance/incidents', { headers: authHeader() });
  }
  getArchiveData() {
    return axios.get(API_URL + 'associates/archiveData', { headers: authHeader() });
  }
  updateArchive(data) {
    return axios.post(API_URL + 'associates/updateArchive',data, { headers: authHeader() });
  }
  getApplicationData() {
    return axios.get(API_URL + 'recruitment/getApplicationData', { headers: authHeader() });
  }
  getOnboardingData() {
    return axios.get(API_URL + 'recruitment/getOnboardingData', { headers: authHeader() });
  }
  updateApplicationData(data) {
    return axios.post(API_URL + 'recruitment/updateApplicationData',data, { headers: authHeader() });
  }
  updateOnboardingData(data) {
    return axios.post(API_URL + 'recruitment/updateOnboardingData',data, { headers: authHeader() });
  }
  getStations() {
    return axios.get(API_URL + 'recruitment/getStations', { headers: authHeader() });
  }
  getCaptureData() {
    return axios.get(API_URL + 'recruitment/getCaptureData', { headers: authHeader() });
  }
  getAdd() {
    return axios.get(API_URL + 'recruitment/getAdd', { headers: authHeader() });
  }
  sendDataCapture(data) {
    return axios.post(API_URL + 'recruitment/sendDataCapture',data, { headers: authHeader() });
  }
  getFiles(data) {
    return axios.post(API_URL + 'associates/files',data, { headers: authHeader() });
  }
  getPolicies() {
    return axios.get(API_URL + 'compliance/getPolicies', { headers: authHeader() });
  }
  getDashData(data) {
    return axios.post(API_URL + 'dash/dashData',data, { headers: authHeader() });
  }
  updatePDetails(data) {
    return axios.post(API_URL + 'dash/updatePDetails',data, { headers: authHeader() });
  }
  importPdf(data) {
    return axios.post(API_URL + 'associates/importPdf',data, { headers: authHeader() });
  }
  getInterviews(data) {
    return axios.post(API_URL + 'ad/interviews',data, { headers: authHeader() });
  }
  sendSlots(data) {
    return axios.post(API_URL + 'ad/sendSlots',data, { headers: authHeader() });
  }
  apply(data) {
    return axios.post(API_URL + 'ad/apply',data, { headers: authHeader() });
  }
  sendNoti(data) {
    return axios.post(API_URL + 'user/notiSend',data, { headers: authHeader() });
  }
  markCreated(data) {
    return axios.post(API_URL + 'user/created',data, { headers: authHeader() });
  }
  sendDataCaptureNotiEmail(data) {
    return axios.post(API_URL + 'recruitment/notiEmail',data, { headers: authHeader() });
  }
  getDebtors() {
    return axios.get(API_URL + 'finance/getDebtors', { headers: authHeader() });
  }
  updateDebtors(data) {
    return axios.post(API_URL + 'finance/updateDebtors',data, { headers: authHeader() });
  }
  createDebtors(data) {
    return axios.post(API_URL + 'finance/createDebtors',data, { headers: authHeader() });
  }
  getArchiveNames() {
    return axios.get(API_URL + 'finance/getArchiveNames', { headers: authHeader() });
  }
  getAmzlMileage() {
    return axios.get(API_URL + 'finance/amzlMileage', { headers: authHeader() });
  }
  insertAmzlMileage(data) {
    return axios.post(API_URL + 'finance/insertAmzlMileage',data, { headers: authHeader() });
  }
  deleteAmzlMileage(data) {
    return axios.post(API_URL + 'finance/deleteAmzlMileage',data, { headers: authHeader() });
  }
  updateAmzlMileage(data) {
    return axios.post(API_URL + 'finance/updateAmzlMileage',data, { headers: authHeader() });
  }
  getRateCard() {
    return axios.get(API_URL + 'finance/rateCard', { headers: authHeader() });
  }
  insertRate(data) {
    return axios.post(API_URL + 'finance/insertRate',data, { headers: authHeader() });
  }
  deleteRate(data) {
    return axios.post(API_URL + 'finance/deleteRate',data, { headers: authHeader() });
  }
  updateRate(data) {
    return axios.post(API_URL + 'finance/updateRate',data, { headers: authHeader() });
  }
  getPaymentDatabase() {
    return axios.get(API_URL + 'finance/paymentDatabase', { headers: authHeader() });
  }
  insertBulkPayment(data) {
    return axios.post(API_URL + 'finance/insertBulkPaymentDatabase',data, { headers: authHeader() });
  }
  deleteSelectedPayments(data) {
    return axios.post(API_URL + 'finance/deleteSelectedPayments',data, { headers: authHeader() });
  }
  deleteDebtor(data) {
    return axios.post(API_URL + 'finance/deleteDebtor',data, { headers: authHeader() });
  }
  getAssociatesNamesSkno() {
    return axios.get(API_URL + 'finance/associatesNamesSkno', { headers: authHeader() });
  }
  getRateForPay() {
    return axios.get(API_URL + 'finance/rateForPay', { headers: authHeader() });
  }
  insertSinglePayment(data) {
    return axios.post(API_URL + 'finance/insertSinglePayment',data, { headers: authHeader() });
  }
  updateBulkPayments(data) {
    return axios.post(API_URL + 'finance/updateBulkPayments',data, { headers: authHeader() });
  }
  getPaymentOsmDatabase() {
    return axios.get(API_URL + 'finance/paymentOsmDatabase', { headers: authHeader() });
  }
  getSinglePaymentData(data) {
    return axios.post(API_URL + 'dash/singlePaymentData',data, { headers: authHeader() });
  }
  getOrhData() {
    return axios.get(API_URL + 'compliance/orhData', { headers: authHeader() });
  }
  resendCreation(data) {
    return axios.post(API_URL + 'recruitment/resendCreation',data, { headers: authHeader() });
  }
  sendScoreCardData(data) {
    return axios.post(API_URL + 'kpi/setScoreCardsData',data, { headers: authHeader() });
  }
  getScoreCardData() {
    return axios.get(API_URL + 'kpi/scoreCardsData', { headers: authHeader() });
  }
  getNamesIds() {
    return axios.get(API_URL + 'kpi/namesIds', { headers: authHeader() });
  }
  updateNameId(data) {
    return axios.post(API_URL + 'kpi/updateNameId',data, { headers: authHeader() });
  }
  updateAdditionalInfo(data) {
    return axios.post(API_URL + 'associates/updateAdditionalInfo',data, { headers: authHeader() });
  }
  resendCreationSenior(data) {
    return axios.post(API_URL + 'associates/resendCreationSenior',data, { headers: authHeader() });
  }
  getDashPerformance(data) {
    return axios.post(API_URL + 'dash/dashPerformance',data, { headers: authHeader() });
  }
  getDocuments(data) {
    return axios.post(API_URL + 'dash/dashDocuments',data, { headers: authHeader(), responseType: "blob" });
  }
  processInvoices(data) {
    return axios.post(API_URL + 'finances/proccessInvoices',data, { headers: authHeader() });
  }
  getInvoiceFiles(data) {
    return axios.post(API_URL + 'associates/invoiceFiles',data, { headers: authHeader() });
  }
  getDebtorsAssociates(data) {
    return axios.post(API_URL + 'finances/debtorsAssciates', data, { headers: authHeader() });
  }
  getPaymentWeeks() {
    return axios.get(API_URL + 'finances/paymentWeeks', { headers: authHeader() });
  }
  getPaymentSummary(data) {
    return axios.post(API_URL + 'finances/paymentSummary', data, { headers: authHeader() });
  }
  getPaymentSumAssociates() {
    return axios.get(API_URL + 'finances/paymentSumAssociates', { headers: authHeader() });
  }
  createDebtorsPDF(data) {
    return axios.post(API_URL + 'finances/createDebtorPdf', data, { headers: authHeader() });
  }
  insertBulkRate(data) {
    return axios.post(API_URL + 'finances/insertBulkRate',data, { headers: authHeader() });
  }
  deleteSelectedRates(data) {
    return axios.post(API_URL + 'finances/deleteSelectedRates',data, { headers: authHeader() });
  }
  importVat(data) {
    return axios.post(API_URL + 'dash/importVat',data, { headers: authHeader() });
  }
  getVatCertificate(data) {
    return axios.post(API_URL + 'tickets/sendVatCertificate',data, { headers: authHeader(), responseType: "blob"  });
  }
  getRevolut() {
    return axios.get(API_URL + 'finances/finances', { headers: authHeader() });
  }
  getScheduleNames() {
    return axios.get(API_URL + 'tools/scheduleNames', { headers: authHeader() });
  }
  getScheduleRate() {
    return axios.get(API_URL + 'tools/rateCard', { headers: authHeader() });
  }
  addDriverInSchedule(data) {
    return axios.post(API_URL + 'tools/addDriver',data, { headers: authHeader()});
  }
  updateSchedule(data) {
    return axios.post(API_URL + 'tools/updateSchedule',data, { headers: authHeader()});
  }
  getScheduleData(data) {
    return axios.post(API_URL + 'tools/scheduleData',data, { headers: authHeader()});
  }
  updateDebtorsBulk(data) {
    return axios.post(API_URL + 'tools/updateDebtorsBulk',data, { headers: authHeader()});
  }
  bulkCreateSchedule(data) {
    return axios.post(API_URL + 'tools/bulkCreateSchedule',data, { headers: authHeader()});
  }
  createBulkDebtors(data) {
    return axios.post(API_URL + 'finances/createBulkDebtors', data, { headers: authHeader()});
  }
  scheduleNotes(data) {
    return axios.post(API_URL + 'tools/scheduleNotes', data, { headers: authHeader()});
  }
  getScheduleNotes(data) {
    return axios.post(API_URL + 'tools/getScheduleNotes', data, { headers: authHeader()});
  }
  getDaySchedule(data) {
    return axios.post(API_URL + 'tools/getDaySchedule', data, { headers: authHeader()});
  }
  createInvoicesPayPlan(data) {
    return axios.post(API_URL + 'finances/createPaymentPlan', data, { headers: authHeader()});
  }
}

export default new UserService();
