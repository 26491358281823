let columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    {
        field: 'sk_no',
        headerName: 'Sk no',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'name',
        headerName: 'Name',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'station',
        headerName: 'Station',
        headerClassName: 'table-header',
        width: 130,
        editable: false,
    },
    {
        field: 'p_sunday',
        headerName: 'Sunday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'p_monday',
        headerName: 'Monday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'p_tuesday',
        headerName: 'Tuesday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'p_wednesday',
        headerName: 'Wednesday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'p_thursday',
        headerName: 'Thursday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'p_friday',
        headerName: 'Friday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'p_saturday',
        headerName: 'Saturday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'sunday',
        headerName: 'Sunday',
        headerClassName: 'table-header',
        width: 180,
        editable: true,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'monday',
        headerName: 'Monday',
        headerClassName: 'table-header',
        width: 180,
        editable: true,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'tuesday',
        headerName: 'Tuesday',
        headerClassName: 'table-header',
        width: 180,
        editable: true,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'wednesday',
        headerName: 'Wednesday',
        headerClassName: 'table-header',
        width: 180,
        editable: true,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'thursday',
        headerName: 'Thursday',
        headerClassName: 'table-header',
        width: 180,
        editable: true,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'friday',
        headerName: 'Friday',
        headerClassName: 'table-header',
        width: 180,
        editable: true,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'saturday',
        headerName: 'Saturday',
        headerClassName: 'table-header',
        width: 180,
        editable: true,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'n_sunday',
        headerName: 'Sunday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'n_monday',
        headerName: 'Monday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'n_tuesday',
        headerName: 'Tuesday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'n_wednesday',
        headerName: 'Wednesday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'n_thursday',
        headerName: 'Thursday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'n_friday',
        headerName: 'Friday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'n_saturday',
        headerName: 'Saturday',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'status',
        headerName: 'Status',
        headerClassName: 'table-header',
        width: 120,
        editable: true,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 120,
        editable: false,
    }

]

export default columns;