import React from "react";
import { useEffect, useState, useMemo } from "react";
import UserService from "../../services/user.service";
import columns from "./sche";
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Select from 'react-select';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AuthService from "../../services/auth.service";


const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      textAlign: 'center',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    [`& .${gridClasses.row}.first`]: {
      backgroundColor: '#f5e0c4',
      borderTop: '1px solid gray',
      borderBottom: '1px solid gray',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    [`& .${gridClasses.cell}.off`]: {
      backgroundColor: '#f5c4c4',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    [`& .${gridClasses.cell}.in`]: {
      backgroundColor: '#aef5b6',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    [`& .${gridClasses.cell}.group`]: {
      backgroundColor: '#cff0fc',
      borderRight: '1px solid gray',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    [`& .${gridClasses.cell}.stations`]: {
      backgroundColor: '#f5f5ae',
      borderRight: '1px solid gray',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    [`& .${gridClasses.cell}.limit`]: {
      //backgroundColor: '#f5f5ae',
      borderLeft: '1px solid gray',
      
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
}));

Date.prototype.getWeek = function() {
  let onejan = new Date(this.getFullYear(),0,1);
  return Math.ceil((((this - onejan) / 86400000) + onejan.getDay()+1)/7);
}

function getDateRangeOfWeek(weekNo, year){
  const firstDayOfYear = new Date(year, 0, 1); const daysToFirstWeek = (firstDayOfYear.getDay() + 6) % 7 + 1 
  const startOfRequestedWeek = new Date(firstDayOfYear) 
  startOfRequestedWeek.setDate(firstDayOfYear.getDate() + (weekNo - 1) * 7 - daysToFirstWeek)
  const startOfLastWeek = new Date(startOfRequestedWeek) 
  startOfLastWeek.setDate(startOfRequestedWeek.getDate() - 7) 
  const endOfNextWeek = new Date(startOfRequestedWeek) 
  endOfNextWeek.setDate(startOfRequestedWeek.getDate() + 13) 
  const dateRange = [] 
  for (let d = new Date(startOfLastWeek); d <= endOfNextWeek; d.setDate(d.getDate() + 1)) { 
    dateRange.push(new Date(d).getDate().toString().padStart(2, '0')+'/'+(new Date(d).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(d).getFullYear())
   } 
   console.log(dateRange)
   return dateRange;
};

function getweekNo(d) {
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  var yearStart = new Date(d.getFullYear(), 0, 1);
  var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
  return [d.getFullYear(), weekNo];
}

function weeksInYear(year) {
  var month = 11,
    day = 31,
    week;
  do {
    let d = new Date(year, month, day--);
    week = getweekNo(d)[1];
  } while (week == 1);

  return week;
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%', minHeight: '20px', fontSize: 'medium', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)'}),
  menu: base => ({
      ...base,
      zIndex: 100,
      width: '101%',
      paddingLeft:'1%',
      marginLeft:'0%',
      
  }), 
}

const yearsOption = [
  {value: new Date().getFullYear()-1, label: new Date().getFullYear()-1},
  {value: new Date().getFullYear(), label: new Date().getFullYear()},
  {value: new Date().getFullYear()+1, label: new Date().getFullYear()+1}
]

const Scheduler = () =>{
    const user = AuthService.getCurrentUser() 
    const [ loading, setLoading ] = useState()
    const [ rawData, setRawData ] = useState([])
    const [ rawReceivedData, setRawReceivedData ] = useState([])
    const [ displayData, setDisplayData ] = useState([])
    const [ week, setWeek ] = useState(new Date().getWeek())
    const [ colPlc, setColPlc ] = useState([])
    const [ stations, setStations ] = useState([])
    const [ station, setStation ] = useState('Select...')
    const [ year, setYear ] = useState(new Date().getFullYear())
    const [ weeksOption, setWeeksOption ] = useState([])
    const [ openAddDriver, setOpenAddDriver ] = useState(false)
    const [ filteredDrivers, setFilteredDrivers ] = useState([])
    const [ addDriver, setAddDriver ] = useState('Select...')
    const [ nodriverWarning, setNoDriverWarning ] = useState("")
    const [ noYearWarning, setNoYearWarning ] = useState("")
    const [ noWeekWarning, setNoWeekWarning ] = useState("")
    const [ tableDataSelect, setTableDataSelect ] = useState([])
    const [ paramChanged, setParamChanged ] = useState()
    const [ scheduleDataPlc, setScheduleDataPlc ] = useState([])
    const [ openSame, setOpenSame ] = useState(false)
    const [ stationsCheck, setStationsCheck ] = useState([])
    const [ pVisibility, setPVisibility ] = useState(false)
    const [ nVisibility, setNVisibility ] = useState(false)
    const [ weekCount, setWeekCount ] = useState(weeksInYear(year))
    const [ pWeek, setPWeek ] = useState()
    const [ nWeek, setNWeek ] = useState()
    const [ pYear, setPYear ] = useState()
    const [ nYear, setNYear ] = useState()
    const [ pData, setPData ] = useState([])
    const [ nData, setNData ] = useState([])
    const [ weekDates, setWeekDates ] = useState([])
    const [ transition, setTransition ] = useState('-13% !important')
    const [ arrow, setArrow ] = useState(<ArrowForwardIosRoundedIcon sx={{ color: 'rgb(45, 117, 131)'}}/>)
    const [ driver, setDriver ] = useState()
    const [ selectedDate, setSelectedDate ] = useState()
    const [ selectedDay, setSelectedDay ] = useState()
    const [ selectedStation, setSelectedStation ] = useState()
    const [ selectedNotes, setSelectedNotes ] = useState()
    const [ checkUpdate, setCheckUpdate ] = useState(false)
    const [ createdBy, setCreatedBy ] = useState()

    const getTreeDataPath = (row) => row.hierarchy;

    const columnVisibilityModel = useMemo(() => {
      let visibleData = {
        id: false, 
        name: false, 
        status: false, 
        station:false,  
        notes: false, 
        sk_no: false,
        p_sunday: false, 
        p_monday: false, 
        p_tuesday: false, 
        p_wednesday: false, 
        p_thursday: false, 
        p_friday: false, 
        p_saturday: false,
        n_sunday: false, 
        n_monday: false, 
        n_tuesday: false, 
        n_wednesday: false, 
        n_thursday: false, 
        n_friday: false, 
        n_saturday: false
      }
      if (pVisibility) {
          visibleData.p_sunday = true
          visibleData.p_monday = true
          visibleData.p_tuesday = true
          visibleData.p_wednesday = true
          visibleData.p_thursday = true
          visibleData.p_friday = true
          visibleData.p_saturday = true
      }
      if (nVisibility) {
        visibleData.n_sunday = true
        visibleData.n_monday = true
        visibleData.n_tuesday = true
        visibleData.n_wednesday = true
        visibleData.n_thursday = true
        visibleData.n_friday = true
        visibleData.n_saturday = true
    }
      return visibleData;
    }, [pVisibility, nVisibility]);

    function CustomToolbar() {
      let weekNumbers = getWeekNumbers(week)
        return (
            <GridToolbarContainer sx={{fontSize: '16px', marginTop: 0}}>
                <div className="inline">
                  <div className="flex">
                    {/*<GridToolbarColumnsButton />*/}
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    {/*<GridToolbarExport/>*/}
                    <Button className="incGridButton" onClick={e => setOpenAddDriver(true)}>
                        <PersonAddAlt1OutlinedIcon fontSize="small" />ADD DRIVER
                    </Button>
                    <GridToolbarQuickFilter style={{marginTop: '15px'}}/>
                    <p className="pInsideTable" style={{marginTop: '15px'}}>Year:</p>
                    <p className="pInsideTable">
                      <Select 
                          options={yearsOption}
                          styles={colourStyles}
                          value={{label: year, value: year}}
                          onChange={e => setYear(e.value)}
                      />
                    </p>
                    <p className="pInsideTable" style={{marginTop: '15px'}}>Week:</p>
                    <p className="pInsideTable">
                      <Select 
                          options={weeksOption}
                          styles={colourStyles}
                          value={{label: week, value: week}}
                          onChange={e => setWeek(e.value)}
                      />
                    </p>
                    <p className="pInsideTable" style={{marginTop: '15px'}}>Station:</p>
                    <p className="pInsideTable">
                      <Select 
                          options={stations}
                          styles={colourStyles}
                          value={{label: station, value: station}}
                          onChange={e => handleStation(e.value)}
                      />
                    </p>
                    {week == new Date().getWeek() ?
                    <FormGroup style={{marginTop: '6px'}}>
                      <FormControlLabel control={<Checkbox sx={{marginLeft: '30px'}} onChange={e => setPVisibility(e.target.checked)} checked={pVisibility}/>} label="Show previous" sx={{height: '10px'}}/>
                      <FormControlLabel control={<Checkbox sx={{marginLeft: '30px'}} onChange={e => setNVisibility(e.target.checked)} checked={nVisibility}/>} label="Show next" sx={{height: '10px'}}/>
                    </FormGroup> : ''
                    } 
                  </div>
                  <div className="flex weekNumbers">
                    {pVisibility ? <p >Week: {weekNumbers[0]}</p> : ''}
                    <p className={pVisibility ? "weekS" : ''}>Week: {weekNumbers[1]}</p>
                    {nVisibility ? <p className="weekS">Week: {weekNumbers[2]}</p> : ''}
                  </div>
                </div>
            </GridToolbarContainer>
        );
    }

    useEffect(()=>{
      if(week !== new Date().getWeek()){
        setPVisibility(false)
      }
    },[week])

    useEffect(()=>{
      UserService.getScheduleRate().then(
        response =>{
          const data = response.data
          setTableDataSelect(data)
        }
      )

      UserService.getStations().then(
        response =>{
          const data = response.data
          let plc = []
          let fPlc = []
          data?.map(row =>{
            if(!row.station_code.match('SD')){
              plc.push({value: row.station_code, label: row.station_code})
              fPlc.push(row.station_code)
            }
          })
          setStationsCheck(fPlc)
          setStations(plc)
        }
      ).catch(err => console.log(err))
      
      let weekPlc = []
      for(let i = 1; i <= weekCount; i++){
        weekPlc.push({value: i, label: i})
      }
      setWeeksOption(weekPlc)
    },[])

    useEffect(()=>{
      setWeekDates(getDateRangeOfWeek(week, year))
    },[week, year])

    function getWeekNumbers(week){
      let plc = []
      if(week == 1){
        plc.push(52)
        plc.push(1)
        plc.push(2)
      }else if(week == 52 || week == 53){
        plc.push(week-1)
        plc.push(week)
        plc.push(1)
      }else{
        plc.push(week-1)
        plc.push(week)
        plc.push(week+1)
      }
      return plc
    }

    useEffect(()=>{
      if(year !== 'Select...' && week !== 'Select...' && station !== 'Select...'){
        let weekDates1 = getDateRangeOfWeek(week, year)
        let weekNumbers = getWeekNumbers(week)
        setDisplayData([])
        let plc = []
        tableDataSelect?.map(row =>{
          plc.push(row.schedule_shortcode)
        })
        plc = [...new Set(plc)]
        columns?.map(row =>{
          if(row.field !== 'id' && row.field !== 'name' && row.field !== 'status' && row.field !== 'notes'){
            row.type = 'singleSelect'
            row.valueOptions = year !=='Select...' && week !== 'Select...' && station !== 'Select...' ? plc : []
          }
        })
        console.log(columns)
        setColPlc(columns)

        UserService.getScheduleNames().then(
          response =>{
            const data = response.data
            console.log(data)
            let fPlc = []
            data?.map(row =>{
              if(row.station == station){
                fPlc.push({sk_no: row.account_no, name: row.name, station: station, year_no: year, week_no: week})
              }
            })
            UserService.bulkCreateSchedule(fPlc).then(
              response =>{
                console.log(response)
                UserService.getScheduleData({week_no: week, year_no: year, station: station}).then(
                  respons =>{
                    const data1 = respons.data
                    let plc = []
                    console.log(data1)
                    plc.push({id: 1000, sk_no: '', hierarchy: ['Date'], name: 'Date', station: '', p_sunday: weekDates1[0], p_monday: weekDates1[1], p_tuesday: weekDates1[2], p_wednesday: weekDates1[3], p_thursday: weekDates1[4], p_friday: weekDates1[5], p_saturday: weekDates1[6], sunday: weekDates1[7], monday: weekDates1[8], tuesday: weekDates1[9], wednesday: weekDates1[10], thursday: weekDates1[11], friday: weekDates1[12], saturday: weekDates1[13], n_sunday: weekDates1[14], n_monday: weekDates1[15], n_tuesday: weekDates1[16], n_wednesday: weekDates1[17], n_thursday: weekDates1[18], n_friday: weekDates1[19], n_saturday: weekDates1[20]})
                    data1?.map((row, index)=>{
                      if(row.station == station){
                        console.log(row)
                        plc.push({id: index, sk_no: row.sk_no, hierarchy: [row.name], name: row.name, station: row.station, sunday: row.sunday, monday: row.monday, tuesday: row.tuesday, wednesday: row.wednesday, thursday: row.thursday, friday: row.friday, saturday: row.saturday})
                      }
                    })
                    plc?.map((row) =>{
                      data1.map((ele, index) =>{
                        if(row.name == ele.name && row.station !== ele.station){
                          plc.push(
                            {
                              hierarchy: [row.name, ele.station],
                              name: row.name,
                              station: ele.station,
                              id: index+new Date().getTime(),
                              sk_no: row.sk_no,
                              sunday: ele.sunday, 
                              monday: ele.monday, 
                              tuesday: ele.tuesday, 
                              wednesday: ele.wednesday, 
                              thursday: ele.thursday, 
                              friday: ele.friday,
                              saturday: ele.saturday
                            }
                          )
                        }
                      })
                    })
                setRawData(plc)
                setDisplayData(plc)
                    let sPlc =[]
                    const sData = data?.filter(row =>{
                      return row.station !== station
                    }) 
                    sData?.map(row =>{
                      sPlc.push({value: row.name, label: row.name})
                    })
                    setFilteredDrivers(sPlc)
                  }
                )
              }
            ).catch(err => console.log(err))
            setRawReceivedData(data)
          }
        ).catch(
          err =>{
            console.log(err)
          }
        )  
      }
    },[year, week, station])

    useEffect(() =>{
      if(weekCount == week){
        setPWeek(week-1)
        setPYear(year)
        setNWeek(1)
        setNYear(year+1)
      }else if(week == 1){
        setPWeek(weeksInYear(year-1))
        setPYear(year-1)
        setNWeek(week+1)
        setNYear(year)
      }else{
        setPWeek(week-1)
        setPYear(year)
        setNWeek(week+1)
        setNYear(year)
      }
    },[weekCount, week, year])

    useEffect(()=>{
      if(pWeek && pYear && displayData){
        UserService.getScheduleData({week_no: pWeek, year_no: pYear}).then(
          response =>{
            setPData(response.data)
          }
        ).catch(err => console.log(err))
      }
    },[pWeek, pYear, displayData])

    useEffect(()=>{
      if(nYear, nWeek){
        UserService.getScheduleData({week_no: nWeek, year_no: nYear}).then(
          response =>{
            setNData(response.data)
          }
        ).catch(err => console.log(err))
      }
    },[nWeek, nYear])

    useEffect(()=>{
      if(pVisibility == true && pYear && pWeek){
            let plc = JSON.parse(JSON.stringify(displayData))
            console.log(plc)
            let plcD = []
            let count = 0
            plc?.map(row =>{
              pData?.map((ele, index) =>{
                if(row.name == ele.name && row.station == ele.station && row.name !== 'Date'){
                  row.p_sunday = ele.sunday
                  row.p_monday = ele.monday
                  row.p_tuesday = ele.tuesday
                  row.p_wednesday = ele.wednesday
                  row.p_thursday = ele.thursday
                  row.p_friday = ele.friday
                  row.p_saturday = ele.saturday
                }else if(row.name == ele.name && row.station !== ele.station && ele.station !== station){
                  console.log(ele.station)
                  console.log(station)
                  plc?.push(
                    {
                      hierarchy: [row.name, ele.station],
                      name: row.name,
                      station: ele.station,
                      id: index+new Date().getTime()+Math.ceil(Math.random()*100),
                      sk_no: row.sk_no,
                      p_sunday: ele.sunday, 
                      p_monday: ele.monday, 
                      p_tuesday: ele.tuesday, 
                      p_wednesday: ele.wednesday, 
                      p_thursday: ele.thursday, 
                      p_friday: ele.friday,
                      p_saturday: ele.saturday
                    }
                  )
                }
              })
            })
            plcD = removeDuplicatesByArrayKey(plc)
        setDisplayData(plcD)
  }else{
    setDisplayData(rawData)
      }
    
    },[pVisibility])

    useEffect(()=>{
      console.log(pData)
    },[pData])

    function removeDuplicatesByArrayKey(objects){ 
      const seen = new Set(); 
      return objects.filter(obj => { 
        const valueArray = obj.station 
        const valueString = JSON.stringify(valueArray)
        if(obj.hierarchy.length > 1 && obj.hierarchy[0] !== station ){
          if(seen.has(valueString)) { 
            return false 
          }else{ 
            seen.add(valueString)
            return true 
          } 
        }else{
          return true
        }
      }) 
    }

    useEffect(()=>{
      if(nVisibility && nYear && nWeek){
          let plc = displayData
          plc?.map(row =>{
            nData?.map(ele =>{
              if(row.name == ele.name && row.station == ele.station){
                row.n_sunday = ele.sunday
                row.n_monday = ele.monday
                row.n_tuesday = ele.tuesday
                row.n_wednesday = ele.wednesday
                row.n_thursday = ele.thursday
                row.n_friday = ele.friday
                row.n_saturday = ele.saturday
              }
            })
      })
      setDisplayData(plc)
      }
    },[nVisibility])

    const handleStation = (value) =>{
      setStation(value)
      const data = rawData.filter(row =>{
        return row.station == value
  })
  setDisplayData(data)
    }

    const addDriverToSchedule = () =>{
      if(addDriver == 'Select...' || week == 'Select...' || year == 'Select...'){
        if(addDriver == 'Select...'){
          setNoDriverWarning('field required')
        }
        if(week == 'Select...'){
          setNoWeekWarning('field required')
        }
        if(year == 'Select...'){
          setNoYearWarning('field required')
        }
      }else{
        rawReceivedData?.map(row =>{
          if(row.name == addDriver){
            const data = {sk_no: row.account_no, name: row.name, station: station, year_no: year, week_no: week}
            UserService.addDriverInSchedule(data).then(
              response =>{
                console.log(response.data)
              }
            ).catch(err => console.log(err.message))
          }
        })
        
      }
      window.location.reload(false)
    }

    const handleCellEdit = (params) =>{
      //setPVisibility(false)
      setNVisibility(false)
      console.log(params)
      let check = false
      let count = 0
      let checkSix = false
      let dataCheck = {}
      dataCheck.name = params.name
      dataCheck.p_sunday = ''
      dataCheck.p_monday = ''
      dataCheck.p_tuesday = ''
      dataCheck.p_wednesday = ''
      dataCheck.p_thursday = ''
      dataCheck.p_friday = ''
      dataCheck.p_saturday = ''
      dataCheck.sunday = params.sunday
      dataCheck.monday = params.monday
      dataCheck.tuesday = params.tuesday
      dataCheck.wednesday = params.wednesday
      dataCheck.thursday = params.thursday
      dataCheck.friday = params.friday
      dataCheck.saturday = params.saturday
      dataCheck.n_sunday = ''
      dataCheck.n_monday = ''
      dataCheck.n_tuesday = ''
      dataCheck.n_wednesday = ''
      dataCheck.n_thursday = ''
      dataCheck.n_friday = ''
      dataCheck.n_saturday = ''
      
      let rawPlc = rawData
      let cData = []

      
      
      pData?.map(row =>{
        if(row.name == params.name){
          const keys = Object.keys(row)
          keys?.map(key =>{
            if(key !== 'id' && key !== 'name' && key !== 'status' && key !== 'createdAt' && key !== 'updatedAt' && key !== 'entry' && key !== 'notes' && key !== 'sk_no' && key !== 'station' && key  !== 'updatedBy' && key !== 'year_no' && key !== 'week_no'){
              if(row[key]?.match('hr')){
                dataCheck[`p_${key}`] = row[key]
              }
            }
          })
        }
      })

      displayData?.map(row =>{
        if(row.name == params.name){
          cData.push(row)
        }
      })

      cData?.map(row =>{
        const keys = Object.keys(row)
        keys?.map(key =>{
          if(key !== 'hierarchy' && key !== 'id' && key !== 'n_sunday' && key !== 'n_monday' && key !== 'n_tuesday' && key !== 'n_wednesday' && key !== 'n_thursday' && key !== 'n_friday' && key !== 'n_saturday' && key !== 'p_sunday' && key !== 'p_monday' && key !== 'p_tuesday' && key !== 'p_wednesday' && key !== 'p_thursday' && key !== 'p_friday' && key !== 'p_saturday' && key !== 'station' && key !== 'name' && key !== 'sk_no' && key !== 'id'){
            console.log(row[key])
            if(row[key]?.match('hr')){
              dataCheck[key] = row[key]
            }
          }
        })
      })

      nData?.map(row =>{
        if(row.name == params.name){
          const keys = Object.keys(row)
          keys?.map(key =>{
            if(key !== 'id' && key !== 'name' && key !== 'status' && key !== 'createdAt' && key !== 'updatedAt' && key !== 'entry' && key !== 'notes' && key !== 'sk_no' && key !== 'station' && key  !== 'updatedBy' && key !== 'year_no' && key !== 'week_no'){
              if(row[key]?.match('hr')){
                dataCheck[`n_${key}`] = row[key]
              }
            }
          })
        }
      })

      const keys = Object.keys(dataCheck)
      console.log(keys)
      keys?.map(key =>{
        console.log(key)
        console.log(dataCheck[key])
        if(key !== 'id' && key !== 'sk_no' && key !== 'hierarchy' && key !== 'name' && key !== 'station'){
          if(dataCheck[key]?.match('hr')){
            count++
          }else{
            if(count == 7){
              check = true
              let plc = displayData
              plc?.map(row =>{
                if(row.name == params.name && row.station == params.station){
                  row[key] = ''
                }
          })
          setDisplayData(plc)
            }else{
              count = 0
            }
          }
        }
      })

      displayData?.map(row =>{
        if(row.name == params.name && row[paramChanged]?.match('hr') && params[paramChanged]?.match('hr') && row.station !== params.station){
          check =true
        }
      })

      rawPlc?.map(row =>{
        if(row.name == params.name && row.station == params.station){
          row[paramChanged] = params[paramChanged]
        }
      })

      setRawData(rawPlc)
      
      if(check == false){
        const data = {sk_no: params.sk_no, name: params.name, station: params.station, year_no: year, week_no: week, [paramChanged]: params[paramChanged]}
        UserService.updateSchedule(data).then(
          response =>{
            console.log(response.data)
          }
        ).catch(err => console.log(err))
        
      }else{
        setOpenSame(true)
        params[paramChanged] = ''
        let plc = displayData
        plc?.map(row =>{
          if(row.name == params.name && row.station == params.station){
            row[paramChanged] = ''
          }
        })
        setRawData(plc)
        setDisplayData(plc)
        
      }
      return params
    }

    useEffect(() =>{
      setPVisibility(false)
      setNVisibility(false)
    }, [week])

    const paperTransition = async () =>{
      if(transition == '10px !important'){
        setTransition('-13% !important')
        setArrow(<ArrowForwardIosRoundedIcon sx={{ color: 'rgb(45, 117, 131)'}}/>)
      }else{
        setTransition('10px !important')
        setArrow(<ArrowBackIosNewRoundedIcon sx={{ color: 'rgb(45, 117, 131)'}}/>)
      }
      
    }
    
    const handleCellSelection = (params) =>{
      console.log(params)
      setCreatedBy('')
      setSelectedNotes('')
      setCheckUpdate(false)
      const field = params.field
      let datePlc = ''
      displayData?.map(row =>{
        if(row.name == 'Date'){
          setSelectedDate(row[field])
          datePlc = row[field]
          setSelectedDay(field)
        }
      })
      setDriver(params.row.name)
      setSelectedStation(params.row.station)
      const data = {driver_name: params.row.name, station: params.row.station, date: datePlc}
      UserService.getScheduleNotes(data).then(
        response =>{
          console.log(response.data)
          setSelectedNotes(response.data[0].note)
          setCreatedBy(response.data[0].created_by)
          
        }
      ).catch(err => console.log(err))
    }

    const updateNotes = () =>{
      const data = {driver_name: driver, station: selectedStation, date: selectedDate, note: selectedNotes, created_by: user.username}
      UserService.scheduleNotes(data).then(
        response =>{
          console.log(response.data)
          setCheckUpdate(false)
        }
      ).catch(err => console.log(err))
    }

    const handleSelecteNotes = (value) =>{
      if(selectedNotes !== value){
        setCheckUpdate(true)
      }else{
        setCheckUpdate(false)
      }
      setSelectedNotes(value)
    }

    return(
        <div className='dashArch'>
          
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Schedule</h3>
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop: 0}}>
              
                <StripedDataGridPro
                    treeData
                    rows={displayData}
                    columns={colPlc}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 50,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[50, 100, 200]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    loading={loading}
                    processRowUpdate={params =>handleCellEdit(params)}
                    onCellEditStop={params => setParamChanged(params.field)}
                    onProcessRowUpdateError={err => console.log(err)}
                    //onRowSelectionModelChange={params => setSelectedIds(params)}
                    //onRowClick={params => console.log(params)}
                    onCellClick={params => handleCellSelection(params)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage === 0 /*|| params.indexRelativeToCurrentPage === 1*/  ? 'first' : params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'  
                    }
                    getCellClassName={(params) => {
                      if (params.field === 'hierarchy' || params.field == 'station') {
                        return 'group';
                      }
                      
                      return params.value=='OFF' && (params.field === 'sunday' || params.field === 'n_sunday') ? 'off limit'  : params.value?.split(' ').length > 1 ? 'group' : params.value?.match('hr') && (params.field === 'sunday' || params.field == 'n_sunday') ? 'in limit'  : stationsCheck?.includes(params.value) ? 'stations' :  params.value?.match('hr') ? 'in' : params.value == 'OFF' ? 'off' :  params.field === 'sunday' || params.field == 'n_sunday' ? 'limit':'';
                  }}
                    //onColumnVisibilityModelChange={param => handlePrevious(param)}    
                    columnVisibilityModel={columnVisibilityModel}
                   
                    getTreeDataPath={getTreeDataPath}
                    {...displayData}
                    isGroupExpandedByDefault={() =>true}
                />
                
            </Box> 
            <Paper elevation={12} sx={{marginLeft: transition , width: '16%', height: '300px', position: 'fixed', top: '40%', left: '-10px'}}>
              <div className="flex">
                <div style={{width: '90%'}}>
                  <h3 style={{marginLeft: '10px', marginTop: '10px', fontSize: '12px'}}><b>Driver: </b></h3>
                  <h3 style={{marginRight: '30px',textAlign: 'right', marginTop: '10px', fontSize: '12px', color: 'rgb(136, 136, 136)', borderBottom: '1px solid rgb(136, 136, 136)'}}>{driver}</h3>
                  <h3 style={{marginLeft: '10px', marginTop: '10px', fontSize: '12px'}}><b>Date: </b></h3>
                  <h3 style={{marginRight: '30px',textAlign:'right', marginTop: '10px', fontSize: '12px', color: 'rgb(136, 136, 136)', borderBottom: '1px solid rgb(136, 136, 136)'}}>{selectedDay?.toUpperCase() && selectedDay ? selectedDay+' ('+selectedDate+')' : ''}</h3>
                  <h3 style={{marginLeft: '10px', marginTop: '10px', fontSize: '12px'}}><b>Notes: </b></h3>
                  
                  <textarea style={{marginLeft: '10px', marginRight: '30px',textAlign:'left', marginTop: '10px', fontSize: '12px', color: 'rgb(136, 136, 136)', border: '1px solid gray', borderRadius: '5px', width: '88%', height: '30%', marginBottom: '0%'}} value={selectedNotes} onChange={e => handleSelecteNotes(e.target.value)}></textarea>
                  {createdBy ? <h3 style={{marginRight: '30px',textAlign:'right', marginTop: '0px', fontSize: '12px', color: 'rgb(136, 136, 136)'}}>Last edit by: <i>{createdBy}</i></h3> : ''}
                  {checkUpdate ? 
                    <button class="buttonSkOn" style={{marginLeft: '10px', marginTop: '10px', marginButtom: '20px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={updateNotes} >
                        <span class="button-text" style={{fontSize: '12px', fontWeight: '600', color:'white'}}>update</span>
                        <div class="fill-container"></div>
                    </button> : ''
                  }
                  
                </div>
                <div style={{textAlign: 'right', maxWidth: '20px !important'}}>
                  <button className="ocButton" onClick={paperTransition}>{arrow}</button>
                </div>
              </div>
            </Paper>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openAddDriver}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Add Driver</label></DialogTitle>
                    <DialogContent>
                      <div className="flex spaceing">
                          <div className="flexComp">
                              <label for="dlNo" className="label2">Seal driver</label>
                              <Select 
                                  options={filteredDrivers}
                                  styles={colourStyles}
                                  value={{label: addDriver, value: addDriver}}
                                  onChange={e => setAddDriver(e.value)}
                              /> 
                          </div>
                      </div>
                      <div className="flexComp">
                          {nodriverWarning? 
                              <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{nodriverWarning}</p> : ''
                          }
                      </div>
                      <div className="flex spaceing">
                          <div className="flexComp">
                              <label for="dlNo" className="label2">Year</label>
                              <Select 
                                  options={yearsOption}
                                  styles={colourStyles}
                                  value={{label: year, value: year}}
                                  onChange={e => setYear(e.value)}
                              /> 
                          </div>
                      </div>
                      <div className="flexComp">
                          {noYearWarning ? 
                              <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{noYearWarning}</p> : ''
                          }
                      </div>
                      <div className="flex spaceing">
                          <div className="flexComp">
                              <label for="dlNo" className="label2">Week</label>
                              <Select 
                                  options={weeksOption}
                                  styles={colourStyles}
                                  value={{label: week, value: week}}
                                  onChange={e => setWeek(e.value)}
                              /> 
                          </div>
                      </div>
                      <div className="flexComp">
                          {noWeekWarning ? 
                              <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{noWeekWarning}</p> : ''
                          }
                      </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={addDriverToSchedule} >
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Add driver</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={e => setOpenAddDriver(false)}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openSame}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Error</label></DialogTitle>
                    <DialogContent>
                     <p style={{ fontSize: '18px', color: 'red'}}>The driver already booked on a conflicting time in another depot or have been booked 6 days already!</p>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={e => setOpenSame(false)}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            
        </div>
    )
}
export default Scheduler;